// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { doc, setDoc, getDoc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDakaRaREBzTh9J8rC6npr4eOFmkpnp6Bg",
    authDomain: "mullerjob-68b96.firebaseapp.com",
    projectId: "mullerjob-68b96",
    storageBucket: "mullerjob-68b96.appspot.com",
    messagingSenderId: "611156700841",
    appId: "1:611156700841:web:91afb6d4a0f0ef5a5183b8",
    measurementId: "G-WP89QLEJ5C"
  };
  
  // apiKey: "AIzaSyDMsQlDgH8d7yP1J1F-Y-NRUPHPH4hUHM0",
  // authDomain: "chideraguy-a419b.firebaseapp.com",
  // projectId: "chideraguy-a419b",
  // storageBucket: "chideraguy-a419b.appspot.com",
  // messagingSenderId: "634529795790",
  // appId: "1:634529795790:web:a74d8cdc5f32c4b1800a9f",
  // measurementId: "G-3XYSBFXT1Y"

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


export { db, setDoc, doc, getDoc };
