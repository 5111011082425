import React, { useState } from "react";
import { db, doc, getDoc } from "../firebaseConfig";
import Receipt from "./Receipt";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Navbar from "./Header";

const HeroSection = () => {
  const [waybillNo, setWaybillNo] = useState("");
  const [shippingData, setShippingData] = useState(null);
  const [error, setError] = useState("");
  const [isTracking, setIsTracking] = useState(false);
  const [showMap, setShowMap] = useState(false); // New state for map modal

  const handleInputChange = (e) => {
    setWaybillNo(e.target.value);
  };

  const fetchData = async () => {
    setError("");
    setShippingData(null);
    setIsTracking(true); // Show the modal

    try {
      const docRef = doc(db, "shippingForms", waybillNo);
      const snapshot = await getDoc(docRef);

      if (snapshot.exists()) {
        setShippingData(snapshot.data());
      } else {
        setError("No data found for the provided Waybill ID.");
        setIsTracking(false); // Reset modal visibility if no data is found
      }
    } catch (err) {
      setError("Error fetching data: " + err.message);
      setIsTracking(false); // Reset modal visibility on error
    }
  };

  // Close the receipt modal
  const handleCancel = () => {
    setIsTracking(false);
    setShippingData(null);
  };

  // Download the receipt as a PDF
  const handleDownload = () => {
    const receiptElement = document.getElementById("receipt"); // The receipt container

    html2canvas(receiptElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("receipt.pdf");
    });
  };

  const handleViewMap = () => {
    setShowMap(true);
  };

  // Close the map modal
  const closeMapModal = () => {
    setShowMap(false);
  };
  return (
    <section
      className="relative bg-cover bg-center h-screen md:h-96 lg:px-8 lg:py-12"
      style={{
        backgroundImage:
          "url('https://www.dhl.com/content/dam/dhl/global/core/images/marketing-stage-2730x1120/employee-receives-customer-signature-0001.web.1920.600.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Gradient Overlay from Bottom */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>

      {/* Main content that will become faint when tracking */}
      <div
        className={`relative flex flex-col items-center justify-center h-full text-white px-4 md:px-8 ${shippingData ? "pointer-events-none opacity-30" : ""
          }`}
      >
        {/* Title */}
        <div>
          <h1 className="text-sm md:text-4xl lg:text-3xl mr-72 mt-28 lg:mt-40 font-bold  mb-4">
            Track Your Shipment
          </h1>
        </div>
        <div className="relative w-full max-w-xl mb-8 flex flex-col md:flex-row items-center">
          <div className="relative w-full md:flex-grow">
            <input
              value={waybillNo}
              onChange={handleInputChange}
              type="text"
              id="trackingNumber"
              className="block px-3 py-3 w-full text-gray-900 bg-white border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-1 focus:ring-red-100 peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="trackingNumber"
              className="absolute left-3 top-3 text-gray-500 duration-300 transform -translate-y-4 scale-90 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-4"
            >
              Enter your tracking number(s)
            </label>
          </div>

          <button
            onClick={fetchData}
            type="submit"
            className="mt-4 md:mt-0 md:ml-4 bg-red-600 hover:bg-red-700 px-4 py-3 rounded-md text-white font-bold w-full md:w-auto transition-colors duration-200 ease-in-out"
          >
            Track
          </button>
        </div>

        {/* Cards section */}
        <div className="flex flex-col md:flex-row w-full max-w-screen-md shadow-xl space-y-4 md:space-y-0 md:space-x-2">
          {/* Card 1 */}
          <div className="bg-white text-gray-800 p-4 flex-1 flex flex-col items-center text-center border-t border-l border-gray-200 h-[120px]">
            <img
              src="https://www.dhl.com/content/dam/dhl/global/core/images/icons/gogreen-icons/glo-core-gogreen-warehousing.svg"
              alt="Ship Now"
              className="mb-2 w-10 h-10"
            />
            <h3 className="text-sm font-bold">Ship Now</h3>
            <p className="text-xs text-gray-600">Find the right service</p>
          </div>

          {/* Card 2 */}
          <div className="bg-white text-gray-800 p-4 flex-1 flex flex-col items-center text-center border-t border-l border-gray-200 h-[120px]">
            <img
              src="https://www.dhl.com/content/dam/dhl/global/core/images/icons/general-icons/glo-core-getaquote.svg"
              alt="Get a Quote"
              className="mb-2 w-10 h-10"
            />
            <h3 className="text-sm font-bold">Get a Quote</h3>
            <p className="text-xs text-gray-600">
              Estimate cost to share and compare
            </p>
          </div>

          {/* Card 3 - Last card (wider, taller) */}
          <div className="bg-white text-gray-800 p-4 flex-[1.5] flex flex-col items-center text-center border-t border-l border-r border-gray-200 relative h-[120px]">
            <div className="absolute top-0 right-0 bg-yellow-500 h-4 w-4"></div>
            <img
              src="https://www.dhl.com/content/dam/dhl/global/core/images/icons/general-icons/glo-core-online.svg"
              alt="DHL for Business"
              className="mb-2 w-10 h-10"
            />
            <h3 className="text-sm font-bold">DHL for Business</h3>
            <p className="text-xs text-gray-600">
              Shipping regularly? Request a business account and profit from
              exclusive benefits
            </p>
          </div>
        </div>
      </div>

      {/* Conditionally render the modal with receipt */}
      {isTracking && shippingData && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
          <div
            id="receipt"
            className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative"
          >
            <Receipt shippingData={shippingData} />

            {/* Action buttons */}
            <div className="flex justify-between mt-4">
              <button
                onClick={handleCancel}
                className="bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 rounded-md"
              >
                Close
              </button>
              <button
                onClick={handleViewMap}
                className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md"
              >
                View Map
              </button>
              <button
                onClick={handleDownload}
                className="bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 rounded-md"
              >
                Download
              </button>
            </div>
          </div>
        </div>
      )}

      {showMap && (
        <>
          <Navbar />
          <div className="fixed inset-0 bg-gray-200 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full flex flex-col md:flex-row items-center text-center md:text-left relative gap-6">

              {/* Map Section */}
              <div className="w-full md:w-1/2 h-64 md:h-96 rounded-lg overflow-hidden order-2 md:order-1">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.317423604073!2d-88.28443318468167!3d41.84443497922112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880f0f4a1e3b13e5%3A0xae86ebb6ea0c03af!2sFOX%20VALLEY%20IL%20DISTRIBUTION%20CENTER!5e0!3m2!1sen!2sus!4v1614267032643!5m2!1sen!2sus"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>

              {/* Tracking Information Section */}
              <div className="w-full md:w-1/2 flex flex-col items-center md:items-start order-1 md:order-2">
                {/* USPS Tracking Header */}
                <h2 className="text-xl font-bold text-blue-800 mb-2">DHL Tracking®</h2>

                {/* Tracking Number Section */}
                <div className="flex flex-col items-center md:items-start justify-center mb-4">
                  <p className="text-gray-700 font-semibold text-sm">Tracking Number:</p>
                  <p className="text-blue-900 font-bold text-lg mb-1">
                    {shippingData.waybillNo}
                  </p>
                  <div className="flex gap-2 text-blue-600 text-sm">
                    <button className="flex items-center gap-1 hover:underline">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M19.707 4.293a1 1 0 010 1.414L13.414 12l6.293 6.293a1 1 0 01-1.414 1.414l-6.293-6.293-6.293 6.293a1 1 0 01-1.414-1.414L10.586 12 4.293 5.707a1 1 0 011.414-1.414l6.293 6.293L18.293 4.293a1 1 0 011.414 0z" />
                      </svg>
                      Copy
                    </button>
                    <button className="flex items-center gap-1 hover:underline">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M12 2a9 9 0 110 18 9 9 0 010-18zm0 16a7 7 0 100-14 7 7 0 000 14zm-2-8h4v4h-4z" />
                      </svg>
                      Add to Informed Delivery
                    </button>
                  </div>
                </div>

                {/* Expected Delivery Section */}
                <div className="bg-blue-100 p-4 rounded-lg w-full mb-4">
                  <p className="text-gray-700 text-xs font-semibold mb-1">Expected Delivery by</p>
                  <div className="flex justify-center md:justify-start items-center text-blue-900 font-bold text-3xl">
                    <p>{shippingData.expectedDeliveryDateTime}</p>

                    {/* <p className="ml-4">by <span className="text-xl font-bold">9:00pm</span></p> */}
                  </div>
                  <p className="text-gray-600 text-sm mt-2">
                    Your item arrived at our DHL facility in FOX VALLEY IL DISTRIBUTION CENTER on
                    <p>
                      {shippingData.receivedDateTime}
                    </p> The item is currently in transit to the
                    destination.
                  </p>
                </div>

                {/* Footer Links */}
                <div className="border-t pt-2 text-left w-full">
                  <p className="text-gray-600 text-sm font-semibold">Get More Out of DHL Tracking:</p>
                  <a href="#" className="text-blue-600 hover:underline text-sm">DHL Tracking Plus®</a>
                </div>

                {/* Close Button */}
                <button
                  onClick={closeMapModal}
                  className="mt-4 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      )}

    </section>
  );
};

export default HeroSection;
