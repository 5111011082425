// src/Footer.js
import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-100 py-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          {/* Logo and Links */}
          <div className="flex flex-col items-center sm:items-start mb-4 sm:mb-0">
            <img
              src="https://www.dhl.com/content/dam/dhl/global/core/images/logos/glo-footer-logo.svg" // Replace with the actual logo URL
              alt="DHL Logo"
              className="mb-4"
            />
            <ul className="flex flex-wrap justify-center sm:justify-start space-x-4 text-sm text-gray-600">
              <li><a href="#" className="hover:underline">Fraud Awareness</a></li>
              <li><a href="#" className="hover:underline">Legal Notice</a></li>
              <li><a href="#" className="hover:underline">Terms of Use</a></li>
              <li><a href="#" className="hover:underline">Privacy Notice</a></li>
              <li><a href="#" className="hover:underline">Dispute Resolution</a></li>
              <li><a href="#" className="hover:underline">Additional Information</a></li>
              <li><a href="#" className="hover:underline">Consent Settings</a></li>
            </ul>
          </div>

          {/* Social Media Icons and Text */}
          <div className="flex flex-col items-center sm:items-end">
            <div className="flex space-x-4 mb-4">
              {/* Replace these placeholders with actual icons */}
              <a href="#" aria-label="YouTube"><i className="fab fa-youtube text-gray-500 hover:text-gray-800"></i></a>
              <a href="#" aria-label="Facebook"><i className="fab fa-facebook-f text-gray-500 hover:text-gray-800"></i></a>
              <a href="#" aria-label="LinkedIn"><i className="fab fa-linkedin text-gray-500 hover:text-gray-800"></i></a>
              <a href="#" aria-label="Instagram"><i className="fab fa-instagram text-gray-500 hover:text-gray-800"></i></a>
            </div>
            <p className="text-sm text-gray-600">
              2024 © - all rights reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
