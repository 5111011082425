import React from 'react';
import Header from './Component/Header';
import HeroSection from './Component/Hero';
import InfoCards from './Component/InfoCard';
import ShippingSection from './Component/InfoCard';
import Layout from './Component/Layout';
import Footer from './Component/Footer';
import TrackForm from './Component/TrackingForm';
import ViewGoods from './Component/ViewGoods';


function App() {
  return (
    <div className=''>
      <Header />
      <HeroSection />
      <InfoCards />
      <Layout />
      <Footer />
      {/* <TrackForm /> */}
      {/* <ViewGoods />    */}
    </div>
  );
}

export default App;
