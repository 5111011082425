import React from 'react';
import Barcode from 'react-barcode';

const Receipt = ({ shippingData }) => {
  return (
    <div className="receipt border p-4 rounded shadow-md bg-white">
      <div className='flex items-center justify-center gap-5 mb-4'>
        <img src="https://www.dhl.com/content/dam/dhl/global/core/images/logos/glo-footer-logo.svg" alt="DHL Logo" className="mb-4 inline" />
        <h2 className="text-lg font-bold mb-4">EXPRESS WORLDWIDE</h2>
      </div>

      {/* Message */}
      <div className="text-center mb-4">
        <p className="text-sm font-semibold text-red-500">Please wait, you'll be given drivers so tracking can commence.</p>
      </div>

      <div className="flex justify-between mb-4">
        {/* Origin and Contact */}
        <div>
          <p className="font-semibold">Origin:</p>
          <p>{shippingData.originCode}</p>
          <p>From: {shippingData.from}</p>
        </div>

        <div>
          <p className="font-semibold">To:</p>
          <p>{shippingData.to}</p>
          <p>{shippingData.toAddress}</p>
        </div>
      </div>

      <div className="mb-4">
        <h3 className="font-semibold">Waybill No:</h3>
        <p>{shippingData.waybillNo}</p>
      </div>

      {/* Shipping Details */}
      <div className="mb-4 flex justify-between">
        <div>
          <p className="font-semibold">Ref:</p>
          <p>{shippingData.ref}</p>
        </div>
        <div>
          <p className="font-semibold">Weight:</p>
          <p>{shippingData.pceShptWeight} kg</p>
        </div>
        <div>
          <p className="font-semibold">Piece:</p>
          <p>{shippingData.piece}</p>
        </div>
      </div>

      {/* "See on Map" Button */}

      <div className="mb-4 flex">
        <div className="w-1/2">
          <h3 className="font-semibold">Contents:</h3>
          <p>{shippingData.contents}</p>
        </div>
        <div className="w-1/2">
          <h3 className="font-semibold">Name:</h3>
          <p>{shippingData.name}</p>
        </div>
      </div>

      {/* Barcode */}
      <div className="barcode mb-4 flex justify-center">
        <Barcode value={shippingData.waybillNo} />
      </div>

      <div className="text-center">
        <p className="font-semibold">Thank you for shipping with us!</p>
      </div>
    </div>
  );
};

export default Receipt;
