import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice, faShippingFast } from "@fortawesome/free-solid-svg-icons";


const Navbar = () => {
    const [isShipDropdownOpen, setIsShipDropdownOpen] = useState(false);
    const [isTrackDropdownOpen, setIsTrackDropdownOpen] = useState(false); // Track dropdown state
    const [trackingInput, setTrackingInput] = useState("");
    const [isTrackingVisible, setIsTrackingVisible] = useState(false);

    const toggleShipDropdown = () => {
        setIsShipDropdownOpen(!isShipDropdownOpen);
        setIsTrackDropdownOpen(false); // Close Track dropdown if Ship is toggled
    };

    const toggleTrackDropdown = () => {
        setIsTrackDropdownOpen(!isTrackDropdownOpen);
        setIsShipDropdownOpen(false); // Close Ship dropdown if Track is toggled
    };

    const handleTrackClick = () => {
        if (trackingInput) {
            setIsTrackingVisible(true);
        } else {
            alert("Please enter a tracking number");
        }
    };

    return (
        <div>
            <header>
                {/* Top Section */}
                <div className="bg-gradient-to-r from-yellow-500 to-yellow-100 flex justify-between items-center md:px-40 -p-9">
                    <div className="flex items-center">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/mop-job.appspot.com/o/png-transparent-dhl-hd-logo-thumbnail-removebg-preview.png?alt=media&token=85e0716c-2f6b-42f9-b466-06a36255cb9b"
                            alt="DHL Logo"
                            className="h-20 md:h-20"
                        />
                    </div>

                    <div className="md:hidden">
                        {/* Hamburger Menu for small screens */}
                        <button className="text-red-600">
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                ></path>
                            </svg>
                        </button>
                    </div>

                    {/* Desktop Navigation */}
                    <div className="hidden md:flex items-center space-x-2 md:space-x-4 text-xs md:text-sm">
                        <a
                            href="https://mydhl.express.dhl/ng/en/ship/delivery-services.html?cid=exp_1.0_get_started_delivery_service"
                            className="hover:underline"
                            onClick={toggleTrackDropdown}
                        >
                            Find a Location <span className="text-[10px] md:text-xs">▼</span>
                        </a>
                        <a href="#" className="hover:underline">
                            🔍 Search
                        </a>
                        <a href="https://www.dhl.com/ng-en/home/location-selector.html" className="hover:underline">
                            🌍 USA
                        </a>
                    </div>
                </div>

                {/* Bottom Section for larger screens */}
                <div className="hidden md:block bg-white">
                    <div className="flex justify-between items-center px-24 lg:px-52 py-4 text-sm border-b-2 border-gray-200">
                        <div className="flex space-x-6">
                            <a
                                href="#"
                                className="hover:underline relative"
                                onClick={toggleTrackDropdown}
                            >
                                <span className="font-semibold">Track</span>
                                <span className="ml-2 text-xs">▼</span>
                            </a>
                            <a
                                href="#"
                                className="hover:underline relative"
                                onClick={toggleShipDropdown}
                            >
                                <span className="font-semibold">Ship</span>
                                <span className="ml-2 text-xs">▼</span>
                            </a>
                        </div>
                        <a href="#" className="hover:underline">
                            Customer Portal Logins <span className="text-xs">▼</span>
                        </a>
                    </div>
                </div>

                {/* Dropdown content */}
                {isTrackDropdownOpen && (
                    <>
                        <div className="bg-white absolute shadow-2xl  z-20 flex flex-col md:flex-row items-start justify-center p-20 h-[55%]">
                            {/* Left Section */}
                            <div className="flex flex-col space-y-4 md:w-1/2 ml-20 -mt-10">
                                <h2 className="text-xl font-bold text-red-600">
                                    TRACK YOUR SHIPMENT
                                </h2>
                                <p>Find the status of your in-transit shipment</p>

                                <div className="= flex-col space-y-2">
                                    <input
                                        type="text"
                                        placeholder="Enter your tracking number(s)"
                                        className="border border-gray-300 rounded-md p-2 w-96 "
                                    />
                                    <button className="bg-red-600 text-white py-2 px-4 rounded-md w-20">
                                        Track
                                    </button>
                                </div>
                            </div>

                            {/* Right Section */}
                            <div className="mt-8 md:-mt-20 md:w-1/2">
                                <img
                                    src="https://www.dhl.com/content/experience-fragments/dhl/en/core/primary_navigation/flyouts/standard_track_desktop/master/_jcr_content/root/container/container_copy/image.coreimg.80.1197.jpeg/1651242672247/deliverer-opening-vehicle-0001.jpeg"
                                    alt="DHL Van"
                                    className="w-[70%] object-cover"
                                />
                            </div>
                        </div>

                    </>
                )}



                {isShipDropdownOpen && (
                    <div className="absolute left-0 w-full bg-white shadow-lg z-20 py-8 border-t-2 border-gray-200">
                        <div className="container mx-auto px-24 lg:px-52 grid grid-cols-4 gap-6">
                            {/* Left Side Buttons */}
                            <div className="col-span-1">
                                <h3 className="text-red-600 font-bold text-lg mb-4">START SHIPPING</h3>
                                <div className="border-t border-gray-300 pt-4 mb-6 -mt-2">
                                </div>
                                {/* Get a Quote Button */}
                                <button className="flex items-center justify-between w-full p-3 border border-gray-300 rounded-md hover:bg-gray-100">
                                    <span className="flex items-center">
                                        {/* Icon for Get a Quote */}
                                        <FontAwesomeIcon icon={faFileInvoice} className="h-5 w-5 mr-3 text-red-600" />
                                        <a href="https://www.dhl.com/ng-en/home/get-a-quote.html">
                                            Get a Quote
                                        </a>
                                    </span>
                                    <span className="text-red-600">→</span>
                                </button>

                                {/* Ship Now Button */}
                                <button className="flex items-center justify-between w-full mt-4 p-3 border border-gray-300 rounded-md hover:bg-gray-100">
                                    <span className="flex items-center">
                                        {/* Icon for Ship Now */}
                                        <FontAwesomeIcon icon={faShippingFast} className="h-5 w-5 mr-3 text-red-600" />
                                        <a href="https://www.dhl.com/ng-en/home/book-online.html">

                                            Ship Now
                                        </a>
                                    </span>
                                    <span className="text-red-600">→</span>
                                </button>
                            </div>

                            {/* Learn More Section */}
                            <div className="col-span-3">
                                {/* Learn more about text with line */}
                                <h4 className="text-lg font-extrabold mb-2">Learn more about</h4>
                                <div className="border-t border-gray-300 pt-4 mb-6">
                                </div>

                                {/* Informational Sections */}
                                <div className="flex justify-between space-x-8 -mt-5">
                                    <div className="w-1/3 border-l pl-4">
                                        <h3 className="font-bold text-lg">Document and Package</h3>
                                        <p className="text-sm text-gray-600 mt-2">Personal and Business</p>
                                        <p className="text-sm text-gray-600">
                                            Learn about shipping options with DHL Express.
                                        </p>
                                        <button className="mt-4 px-4 py-2 border border-red-600 text-red-600 font-semibold rounded-md hover:bg-red-500 hover:text-white">
                                            <a href="https://www.dhl.com/ng-en/home/express.html">
                                                Explore DHL Express
                                            </a>
                                        </button>
                                    </div>
                                    <div className="w-1/3 border-l pl-4">
                                        <h3 className="font-bold text-lg">Pallets, Containers and Cargo</h3>
                                        <p className="text-sm text-gray-600 mt-2">Business Only</p>
                                        <p className="text-sm text-gray-600">
                                            Air and ocean freight, plus customs and logistics services with DHL Global Forwarding.
                                        </p>
                                        <button className="mt-4 px-4 py-2 border border-red-600 text-red-600 font-semibold rounded-md hover:bg-red-500 hover:text-white">
                                            <a href="https://www.dhl.com/ng-en/home/global-forwarding.html">
                                                Explore Freight Services

                                            </a>
                                        </button>
                                    </div>
                                    <div className="w-1/3 border-l pl-4">
                                        <h3 className="font-bold text-lg">DHL for Business</h3>
                                        <p className="text-sm text-gray-600 mt-2">Frequent Shippers</p>
                                        <p className="text-sm text-gray-600">
                                            Ship regularly or often, learn about the benefits of opening an account.
                                        </p>
                                        <button className="mt-4 px-4 py-2 border border-red-600 text-red-600 font-semibold rounded-md hover:bg-red-500 hover:text-white">
                                            <a href="https://www.dhl.com/ng-en/home/dhl-for-business.html">
                                                Frequent Shipping Options
                                            </a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


            </header>

            {/* Track Your Shipment Section */}

        </div>
    );
};

export default Navbar;
