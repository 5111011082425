import React from 'react';

const ShippingSection = () => {
    return (
        <>
            {/* First Section: Document and Parcel Shipping */}
            <section className="py-12 mt-44 lg:ml-60">
                <div className="container mx-auto flex flex-col md:flex-row-reverse items-center md:items-start justify-between px-4">
                    {/* Right Section: Image */}
                    <div className="w-full md:w-2/4 mb-6 lg:mr-80 md:mb-0">
                        <img
                            src="https://www.dhl.com/content/experience-fragments/dhl/en/core/sub_page_teaser/image_right_2_greycolumns/xbu_master_spt_dhl_home_document_and_package_shipping/_jcr_content/root/container_copy_copy/container_copy/image.coreimg.80.385.jpeg/1655483113358/woman-signs-for-package-0002.jpeg"
                            alt="DHL delivery"
                            className="shadow-lg w-full"
                        />
                    </div>

                    {/* Left Section: Text and Button */}
                    <div className="bg-white shadow-lg p-6 rounded-lg w-full md:w-2/5">
                        <h2 className="text-2xl font-bold mb-4">
                            Document and Parcel Shipping
                        </h2>
                        <p className="text-lg mb-6 text-gray-700">For All Shippers</p>
                        <p className="text-sm text-gray-600 mb-8">
                            Learn about DHL Express – the undisputed global leader in international express shipping.
                        </p>

                        {/* Services Available */}
                        <div className="bg-gray-100 p-4 rounded-lg mb-8">
                            <h3 className="font-bold mb-4 text-gray-700">Services Available</h3>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="flex items-center">
                                    <img
                                        src="https://www.dhl.com/content/dam/dhl/global/core/images/icons/general-icons/glo-core-online.svg"
                                        alt="Next Possible Business Day"
                                        className="w-6 h-6 mr-2"
                                    />
                                    <span className="text-gray-700">Next Possible Business Day</span>
                                </div>
                                <div className="flex items-center">
                                    <img
                                        src="https://www.dhl.com/content/dam/dhl/global/core/images/icons/general-icons/glo-core-getaquote.svg"
                                        alt="Tailored Business Solutions"
                                        className="w-6 h-6 mr-2"
                                    />
                                    <span className="text-gray-700">Tailored Business Solutions</span>
                                </div>
                                <div className="flex items-center">
                                    <img
                                        src="https://www.dhl.com/content/dam/dhl/global/core/images/icons/general-icons/glo-core-online.svg"
                                        alt="Flexible Import/Export Options"
                                        className="w-6 h-6 mr-2"
                                    />
                                    <span className="text-gray-700">Flexible Import/Export Options</span>
                                </div>
                                <div className="flex items-center">
                                    <img
                                        src="https://www.dhl.com/content/dam/dhl/global/core/images/icons/general-icons/glo-core-getaquote.svg"
                                        alt="Wide Variety of Optional Services"
                                        className="w-6 h-6 mr-2"
                                    />
                                    <span className="text-gray-700">Wide Variety of Optional Services</span>
                                </div>
                            </div>
                        </div>

                        {/* Button */}
                        <button className="bg-red-600 text-white font-bold py-3 px-6 rounded-lg hover:bg-red-700 transition duration-300">
                            Explore DHL Express
                        </button>
                    </div>
                </div>
            </section>

            {/* Second Section: Cargo Shipping */}
            <section className="py-12 mt-12 lg:ml-60">
                <div className="container mx-auto flex flex-col-reverse md:flex-row items-center md:items-start justify-between px-4">
                    {/* Left Section: Image */}
                    <div className="w-full md:w-2/5 mb-6 lg:ml-0 md:mb-0">
                        <img
                            src="https://www.dhl.com/content/experience-fragments/dhl/en/core/sub_page_teaser/image_left_2_greycolumns/xbu_standard_spt_dhl_home_pallets_containers_and_cargo/_jcr_content/root/container_copy_copy_/container_copy/image.coreimg.80.1197.jpeg/1683030270879/workers-at-the-container-port-0001.jpeg"
                            alt="Cargo Shipping"
                            className="shadow-lg w-full"
                        />
                    </div>

                    {/* Right Section: Text and Button */}
                    <div className="bg-white shadow-lg p-6 rounded-lg w-full md:w-2/5 lg:mr-80">
                        <h2 className="text-2xl font-bold mb-4">
                            Cargo Shipping
                        </h2>
                        <p className="text-lg mb-6 text-gray-700">Business Only</p>
                        <p className="text-sm text-gray-600 mb-8">
                            Discover shipping and logistics service options from DHL Global Forwarding.
                        </p>

                        {/* Services Available */}
                        <div className="bg-gray-100 p-4 rounded-lg mb-8">
                            <h3 className="font-bold mb-4 text-gray-700">Services Available</h3>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="flex items-center">
                                    <img
                                        src="https://www.dhl.com/content/dam/dhl/global/core/images/icons/general-icons/glo-core-air.svg"
                                        alt="Air Freight"
                                        className="w-6 h-6 mr-2"
                                    />
                                    <span className="text-gray-700">Air Freight</span>
                                </div>
                                <div className="flex items-center">
                                    <img
                                        src="https://www.dhl.com/content/dam/dhl/global/core/images/icons/general-icons/glo-core-ocean.svg"
                                        alt="Ocean Freight"
                                        className="w-6 h-6 mr-2"
                                    />
                                    <span className="text-gray-700">Ocean Freight</span>
                                </div>
                                <div className="flex items-center">
                                    <img
                                        src="https://www.dhl.com/content/dam/dhl/global/core/images/icons/general-icons/glo-core-road.svg"
                                        alt="Road Freight"
                                        className="w-6 h-6 mr-2"
                                    />
                                    <span className="text-gray-700">Road Freight</span>
                                </div>
                                <div className="flex items-center">
                                    <img
                                        src="https://www.dhl.com/content/dam/dhl/global/core/images/icons/general-icons/glo-core-rail.svg"
                                        alt="Rail Freight"
                                        className="w-6 h-6 mr-2"
                                    />
                                    <span className="text-gray-700">Rail Freight</span>
                                </div>
                            </div>
                        </div>

                        {/* Button */}
                        <button className="bg-red-600 text-white font-bold py-3 px-6 rounded-lg hover:bg-red-700 transition duration-300">
                            Explore DHL Global Forwarding
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ShippingSection;
